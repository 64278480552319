import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { encodeParameter, decodeParameter } from '../utils';

export default function SelectTarget({ allTargets, sx }) {
  const targets = allTargets.data.map(row => [row.desg, ...(row.altdesgs?.split(",") || [])].join(", "));
  const params = useParams();

  const target = decodeParameter(params.target);
  const comets = allTargets.data.filter(row => row.isComet).map(row => row.desg);
  const notComets = allTargets.data.filter(row => !row.isComet).map(row => row.desg);

  const getRandomTarget = (targets) => {
    const pick = Math.floor(Math.random() * targets.length);
    return targets[pick];
  };

  const navigate = useNavigate();
  const openTarget = (newTarget) => {
    navigate(`${process.env.PUBLIC_URL}/target/${encodeParameter(newTarget.split(",")[0])}`);
  };

  return (
    <Box sx={{ ...sx }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <FormControl sx={{ width: '15em' }}>
          <Autocomplete
            id="target-field"
            options={targets}
            renderInput={(params) => <TextField {...params} label="Target" fullWidth={true} />}
            onChange={(event, value) => { openTarget(value) }}
            value={target}
          />
        </FormControl>
        <Stack>
          <Button color="primary" onClick={() => openTarget(getRandomTarget(comets))}>Random comet</Button>
          <Button color="primary" onClick={() => openTarget(getRandomTarget(notComets))}>Random not comet</Button>
          <Button color="primary" onClick={() => openTarget(getRandomTarget(targets))}>Random target</Button>
        </Stack>
      </Stack>
    </Box>
  );
}
