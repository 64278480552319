import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from "@mui/material/Grid";
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';

import FooterButton from '../components/FooterButton';
import Lightcurve from '../components/Lightcurve';
import SelectTarget from '../components/SelectTarget';
import StackHeader from '../components/StackHeader';
import StackMetadata from '../components/StackMetadata';
import StackNavigation from '../components/StackNavigation';
import StackNotes from '../components/StackNotesByTarget';
import StackViewer from '../components/StackViewer';
import Table from '../components/Table';
import TargetNotes from '../components/TargetNotes';

import { decodeParameter, magnitudeGetter, useDownloadableTable, disposition, filterVotesByUser, stackNavigator } from '../utils';
import { useTargetPhotometry } from '../services/ztf';
import { useBadPhotometry, useOutburstsByTarget } from '../services/firebase';
import { useLayout, useStackIndex } from '../services/cookies';

function isPointIn(key, pointList) {
  const votes = (pointList || {})[key];
  return disposition(votes);
}

function formatUrl(text) {
  return (url) => (
    <Button
      color="primary"
      component="a"
      href={url}
    >
      {text}
    </Button>
  );
}

export function formatFlag(flag) {
  return [0, 1, 2, 3, 4, 5].map(val => flag & val && val)
    .filter(val => val !== 0)
    .join(',');
}

export default function Target({ allTargets, user, users, apiToken }) {
  const params = useParams();
  const target = decodeParameter(params.target);
  const [stackIndex, setStackIndex] = useStackIndex(target);
  const [searchParams, setSearchParams] = useSearchParams();

  const [layout, setLayout] = useLayout();
  const [stacks, setStacks] = React.useState([]);
  const [selectedStackIndices, setSelectedStackIndices] = React.useState([]);
  const [aperture, setAperture] = React.useState(5);

  // if the data set changes then the stackIndex might become invalid
  if (stacks.length && (stacks[stackIndex] == undefined))
    setStackIndex(0);  // reset to 0

  const phot = useTargetPhotometry(target, apiToken);
  const photTableURL = useDownloadableTable(phot);
  const targetSummary = (target && allTargets.data.find((row) => (row.desg === target)) || {});
  const objid = targetSummary?.objid;
  const altDesgs = targetSummary?.altdesgs?.split(",") || [];
  const outbursts = useOutburstsByTarget(objid, users);
  const badPoints = useBadPhotometry(objid);
  const stack = stacks.length ? stacks[stackIndex] : null;

  React.useEffect(() => {
    if (phot.data.stacks.length)
      setStacks(phot.data.stacks
        // annotate with stack index
        .map((stack, i) => ({
          ...stack,
          index: i
        }))
      );
  }, [phot.data.stacks])

  React.useEffect(() => {
    console.log('object id', objid);
    document.title = "ZBrowser2 : " + (target || "Target");
  }, [target]);

  const stackNavigation = stackNavigator(stacks, stackIndex, setStackIndex, selectedStackIndices);

  React.useEffect(() => {
    const basename = searchParams.get("stack");
    // if a specific stack is requested, we cannot display it until the stacks
    // are loaded
    if (basename && (stacks.length > 0)) {
      stackNavigation.viewByBasename(basename);
      setSearchParams({});
    }
  }, [searchParams, stacks]);

  // validate current stack index value, correct as needed
  if ((selectedStackIndices.length > 0) && (!selectedStackIndices.includes(stackIndex))) {
    stackNavigation.first();
  }

  const changeLayout = (event) => {
    event.target.checked ? setLayout("horizontal") : setLayout("vertical");
  };
  const itemSizes = (layout === "horizontal") ? { xs: 12, md: 6 } : { xs: 12 };

  const highlight = (phot) => {
    const highlights = []

    const myMasked = Object.entries(badPoints || {})
      .filter(([pid, votes]) => disposition(filterVotesByUser(votes, user.uid)))
      .map(([pid, votes]) => parseInt(pid));
    const myMaskedPhotometry = phot.filter(row => myMasked.includes(parseInt(row.pid)));

    highlights.push({
      name: 'Masked by me',
      x: myMaskedPhotometry.map(row => row.x),
      y: myMaskedPhotometry.map(row => row.y),
      mode: 'markers',
      type: 'scatter',
      marker: {
        size: 7,
        opacity: 0.5,
        color: 'red',
        symbol: 'x-thin',
        line: {
          color: 'red',
          width: 1.5
        }
      },
      unselected: {
        marker: {
          opacity: 0.5
        }
      }
    });

    const otherMasked = Object.entries(badPoints || {})
      .filter(([pid, votes]) => disposition(filterVotesByUser(votes, user.uid, true)))
      .map(([pid, votes]) => parseInt(pid));
    const otherMaskedPhotometry = phot.filter(row => otherMasked.includes(parseInt(row.pid)));

    highlights.push({
      name: 'Masked by others',
      x: otherMaskedPhotometry.map(row => row.x),
      y: otherMaskedPhotometry.map(row => row.y),
      mode: 'markers',
      type: 'scatter',
      marker: {
        size: 7,
        opacity: 0.5,
        color: 'blue',
        symbol: 'x-thin',
        line: {
          color: 'blue',
          width: 1.5
        }
      },
      unselected: {
        marker: {
          opacity: 0.5
        }
      }
    });

    const outburstPhotometry = phot.filter((row) =>
      outbursts.confirmedDates.has(row.obsdate.substring(0, 10))
    );

    highlights.push({
      name: 'Outbursts',
      x: outburstPhotometry.map((row) => row.x),
      y: outburstPhotometry.map((row) => row.y),
      mode: 'markers',
      type: 'scatter',
      marker: {
        size: 12,
        color: 'red',
        symbol: 'circle-open'
      },
      unselected: {
        marker: {
          opacity: 1
        }
      }
    });

    return highlights;
  };

  const selectedStackids = new Set(selectedStackIndices.map(i => stacks[i].stackid));
  const tags = {
    Selected: new Set(phot.data.table.filter(row => selectedStackids.has(row.stackid)).map(row => row.pid)),
    "Current stack": new Set(stack && phot.data.table.filter(row => row.stackid === stack.stackid).map(row => row.pid))
  };

  const magnitude = magnitudeGetter(aperture);
  const table = phot.data.table.map((row) => {
    const { m, merr } = magnitude(row);
    const outburst = outbursts.confirmedDates.has(row.obsdate.substring(0, 10)) ? "Outburst!" : "";
    const bad = isPointIn(row.pid, badPoints) ? "Bad" : "";
    return { ...row, m, merr, outburst, bad };
  });

  return (
    <>
      <Box sx={{ display: 'flex', mb: 4 }}>
        <SelectTarget
          allTargets={allTargets}
          sx={{ display: 'flex', flexGrow: 1 }}
        />
        <FormControlLabel
          control={<Switch checked={layout === "horizontal"} onChange={changeLayout} />}
          label="Horizontal layout"
          sx={{ display: 'flex' }}
        />
      </Box>

      {objid && phot.isLoading &&
        <Container><CircularProgress /></Container>
      }
      <Stack spacing={2}>
        {target && !objid &&
          <Alert severity="error">{target} not found in database.</Alert>
        }
        {objid && phot.error &&
          <Alert severity="error">{phot.error}</Alert>
        }
        {target && !phot.isLoading && phot.data.table.length === 0 &&
          <Alert severity="info">No photometry for this comet.</Alert>
        }
        {target && !phot.isLoading && phot.data.stacks.length === 0 &&
          <Alert severity="info">No stacked data for this comet.</Alert>
        }
      </Stack>
      {objid && stacks[stackIndex] &&
        <>
          <TargetNotes allTargets={allTargets} user={user} users={users} objid={objid} />
          <Grid container>
            <Grid item {...itemSizes}>
              <Lightcurve
                target={target}
                objid={objid}
                altDesgs={altDesgs}
                photometry={phot.data.table}
                stacks={stacks}
                stackIndex={stackIndex}
                stackNavigation={stackNavigation}
                selectedStackIndices={selectedStackIndices}
                setSelectedStackIndices={setSelectedStackIndices}
                highlight={highlight}
                setAperture={setAperture}
                user={user}
              />
            </Grid>
            <Grid item {...itemSizes}>
              <StackHeader target={target} stack={stack} apiToken={apiToken} align="center" />
              <StackViewer
                stackIndex={stackIndex}
                stacks={stacks}
                align="center"
              />
              {stacks[stackIndex] && <StackNavigation
                stackIndex={stackIndex}
                selectedStackIndices={selectedStackIndices}
                stacks={stacks}
                stackNavigation={stackNavigation}
                align="center"
              />}
              <hr />
              <StackMetadata stack={stacks[stackIndex]} />
            </Grid>
          </Grid>
          <StackNotes
            allTargets={allTargets}
            objid={objid}
            user={user}
            users={users}
            stack={stacks[stackIndex]}
            stackNavigation={stackNavigation}
            outbursts={outbursts}
          />
        </>
      }
      <FooterButton title="Target photometry" download={photTableURL}>
        <Table
          columns={[
            { field: 'programid', label: 'PID', width: 50, numeric: true },
            {
              field: 'obsdate',
              label: 'Date',
              width: 200,
              date: true,
              formatter: (obsdate, row) =>
                <Button
                  color="primary"
                  onClick={() => stackNavigation.view(
                    stacks.find((stack) => stack.stackid === row.stackid)
                  )}
                >
                  {obsdate}
                </Button>
            },
            { field: 'mjd', label: 'MJD (days)', width: 75, numeric: true },
            { field: 'tmtp', label: 'T-Tp (days)', width: 75, numeric: true },
            { field: 'rh', label: 'rh (au)', width: 75, numeric: true },
            { field: 'delta', label: 'Δ (au)', width: 75, numeric: true },
            { field: 'phase', label: 'phase (°)', width: 50, numeric: true },
            { field: 'selong', label: 'solar elongation (°)', width: 100, numeric: true },
            { field: 'trueanomaly', label: 'true anomaly (°)', width: 100, numeric: true },
            { field: 'vmag', label: 'V (mag)', width: 75, numeric: true },
            { field: 'filter', label: 'Filter', width: 75, numeric: true },
            { field: 'm', label: `m(${aperture})`, width: 75, numeric: true },
            { field: 'merr', label: `σ(${aperture})`, width: 75, numeric: true },
            { field: 'ostat', label: 'O', width: 75, numeric: true },
            { field: 'bad', label: "Bad", width: 75 },
            { field: 'outburst', label: "Outburst", width: 100 },
            { field: 'flag', label: 'Flag', width: 75, formatter: formatFlag },
            { field: 'centroid_offset', label: 'Centroid offset (pix)', width: 75, numeric: true },
            { field: 'mu', label: 'μ ("/hr)', width: 75, numeric: true },
            { field: 'eph_unc', label: 'Pos. unc (")', width: 75, numeric: true },
            { field: 'seeing', label: 'Seeing (")', width: 75, numeric: true },
            { field: "sciimg_url", label: "Sci img", width: 75, formatter: formatUrl("Sci") },
            { field: "diffimg_url", label: "Diff img", width: 75, formatter: formatUrl("Diff") }
          ]}
          rows={table}
          taggedBy="pid"
          tags={tags}
          heightOverhead={260}
        />
      </FooterButton>
    </>
  );
}